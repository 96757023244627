import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Tab, Nav, Card, Alert } from "react-bootstrap";
import { FormInput } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getProfile, updatePassword, updateProfile } from "../../../helpers";
import { AUTH_SESSION_KEY } from "../../../helpers/api/apiCore";
import { authApiResponseSuccess } from "../../../redux/actions";
import { AuthActionTypes } from "../../../redux/auth/constants";

const PersonalInfo = () => {
  const [error, setError] = useState("");
  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        first_name: yup.string().required("First Name is required"),
        last_name: yup.string().required("Last Name is required"),
        email: yup
          .string()
          .email("Invalid email")
          .required("Email is required"),
        phone_number: yup.string().required("Phone Number is required"),
      })
    ),
    defaultValues: user,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      setError("");
      const response = await updateProfile(data);
      if (!response.data.success) throw response.data;
      const profile = await getProfile();
      if (!profile.data.success) throw profile.data;
      dispatch(
        authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {
          ...profile.data.data,
          token: user.token,
        })
      );
      localStorage.setItem(
        AUTH_SESSION_KEY,
        JSON.stringify({
          ...profile.data.data,
          token: user.token,
        })
      );
    } catch (error: any) {
      console.log(error);
      setError(error);
    }
  };

  return (
    <>
      <h5 className="mb-4 text-uppercase">
        <i className="mdi mdi-account-circle me-1"></i> Personal Info
      </h5>
      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <FormInput
              label="First Name"
              type="text"
              name="first_name"
              placeholder="Enter first name"
              containerClass={"mb-3"}
              register={register}
              key="first_name"
              errors={errors}
              control={control}
            />
          </Col>
          <Col md={6}>
            <FormInput
              label="Last Name"
              type="text"
              name="last_name"
              placeholder="Enter last name"
              containerClass={"mb-3"}
              register={register}
              key="last_name"
              errors={errors}
              control={control}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-3">
            <FormInput
              label="Email Address"
              type="text"
              name="email"
              placeholder="Enter email"
              register={register}
              key="email"
              errors={errors}
              control={control}
              disabled
            />
          </Col>
          <Col md={6} className="mb-3">
            <FormInput
              label="Phone Number"
              type="text"
              name="phone_number"
              placeholder="Enter phone number"
              register={register}
              key="phone_number"
              errors={errors}
              control={control}
              disabled
            />
          </Col>
        </Row>

        <div className="text-end">
          <button type="submit" className="btn btn-success mt-2">
            <i className="mdi mdi-content-save"></i> Save
          </button>
        </div>
      </form>
    </>
  );
};

const PasswordInfo = () => {
  const [error, setError] = useState("");
  const schema = yup.object().shape({
    old_password: yup.string().required("Current Password is required"),
    new_password: yup.string().required("New Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      setError("");
      const response = await updatePassword(data);
      if (!response.data.success) throw response.data;
    } catch (error: any) {
      console.log(error);
      setError(error);
    }
  };

  return (
    <>
      <h5 className="mb-3 text-uppercase">
        <i className="mdi mdi-key me-1"></i> Change Password
      </h5>

      {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12} className="mb-3">
            <FormInput
              label="Current Password"
              type="password" // Change type to password for sensitive information
              name="old_password"
              placeholder="Enter current password"
              register={register}
              key="old_password"
              errors={errors}
              control={control}
            />
          </Col>
          <Col md={12} className="mb-3">
            <FormInput
              label="New Password"
              type="password" // Change type to password for sensitive information
              name="new_password"
              placeholder="Enter new password"
              register={register}
              key="new_password"
              errors={errors}
              control={control}
            />
          </Col>
          <Col md={12} className="mb-3">
            <FormInput
              label="Confirm Password"
              type="password" // Change type to password for sensitive information
              name="confirm_password"
              placeholder="Confirm new password"
              register={register}
              key="confirm_password"
              errors={errors}
              control={control}
            />
          </Col>
        </Row>

        <div className="text-end">
          <button type="submit" className="btn btn-success mt-2">
            <i className="mdi mdi-content-save"></i> Save
          </button>
        </div>
      </form>
    </>
  );
};

const Settings = () => {
  return (
    <>
      <Tab.Container defaultActiveKey="personal_info">
        <Card>
          <Card.Body>
            <Nav
              variant="pills"
              as="ul"
              className="nav nav-pills nav-fill navtab-bg"
            >
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  href="#"
                  eventKey="personal_info"
                  className="nav-link cursor-pointer"
                >
                  Personal Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  href="#"
                  eventKey="security"
                  className="nav-link cursor-pointer"
                >
                  Security
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="personal_info">
                <PersonalInfo />
              </Tab.Pane>
              <Tab.Pane eventKey="security">
                <PasswordInfo />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Card>
      </Tab.Container>
    </>
  );
};

export default Settings;
