import { APICore } from "./apiCore";

const api = new APICore();

export function getUsersCancelledBookings({
  page,
  pageSize,
}: {
  page?: number;
  pageSize?: number;
}) {
  const endpoint = "/bookings/user/bookings/?status=cancelled";
  return api.get(`${endpoint}`);
}

export function getUsersStats({
  page,
  pageSize,
}: {
  page?: number;
  pageSize?: number;
}) {
  const endpoint = "/accounts/user/dashboard/";
  return api.get(`${endpoint}`);
}
