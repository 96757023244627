import React, { useState } from "react";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

// components
import { useQuery } from "react-query";
import dayjs from "dayjs";
import {
  getSubscriptionHistory,
  getSubscriptions,
} from "../../../helpers/api/subscription";
import Table from "../../../components/Table/Table";
import { startCase } from "lodash";

const SubscriptionList = () => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    number | null
  >(null);

  const { data: events } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => getSubscriptions(),
  });

  const { data: historyData, isLoading: loading } = useQuery(
    ["subscriptionHistory", selectedSubscriptionId],
    () => getSubscriptionHistory(selectedSubscriptionId!),
    {
      enabled: !!selectedSubscriptionId,
    }
  );

  const fetchHistory = (subscriptionId: number) => {
    setSelectedSubscriptionId(subscriptionId);
    setShowHistoryModal(true);
  };

  const closeHistoryModal = () => {
    setShowHistoryModal(false);
    setSelectedSubscriptionId(null);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "product.name",
      sort: true,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: true,
      Cell: ({ row }: { row: any }) => startCase(row.original.status),
    },
    {
      Header: "Price",
      accessor: "product.price_amount",
      sort: true,
      Cell: ({ row }: { row: any }) => (
        <>${row.original.product.price_amount}</>
      ),
    },
    {
      Header: "Subscription Date",
      accessor: "created_at",
      sort: true,
      Cell: ({ row }: { row: any }) =>
        dayjs(row.original?.created_at).format("DD MMM, YYYY"),
    },
    {
      Header: "History",
      accessor: "action",
      sort: false,
      Cell: ({ row }: { row: any }) => (
        <>
          <Link
            to="#"
            className="action-icon"
            onClick={() => fetchHistory(row.original.id)}
          >
            <i className="mdi mdi-eye"></i>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="justify-content-between">
            <Col>
              <Link
                className="btn btn-primary waves-effect waves-light mb-2"
                to={`/apps/subscription/subscribe`}
              >
                Add Subscription
              </Link>
            </Col>
            <Col></Col>
          </Row>
          <Table
            columns={columns}
            data={events?.data?.data || []}
            pageSize={10}
            isSortable={true}
            pagination={true}
            isSearchable={true}
            tableClass="table-nowrap table-hover"
            searchBoxClass="my-2"
          />
        </Card.Body>
      </Card>

      <Modal show={showHistoryModal} onHide={closeHistoryModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Payment History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading...</p>
          ) : historyData?.data?.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {historyData?.data?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>${item.amount}</td>
                    <td>{dayjs(item.date).format("DD MMM, YYYY")}</td>
                    <td>{startCase(item.status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No payment history available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeHistoryModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriptionList;
